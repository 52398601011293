<template>
  <nav class="nav">
    <div class="title">surpy.</div>
    <div class="middle">
      <div class="router-item">
        <router-link to="/" class="nav-item">HOME</router-link>
      </div>
      <div class="router-item">
        <router-link to="/" class="nav-item">ABOUT ME</router-link>
      </div>
      <div class="router-item">
        <router-link to="/" class="nav-item">MY WORK</router-link>
      </div>
    </div>
    <div class="router-item loginlogout">
      <button @click="signInWithGoogle" class="nav-item" v-if="!isLoggedIn">LOGIN</button>
    </div>
    <div class="router-item">
      <button @click="logout" class="nav-item" v-if="isLoggedIn">LOGOUT</button>
    </div>
  </nav>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';

export default {
  name: 'TopNav',
  setup() {
    const user = ref(null);
    const route = useRoute();
    const auth = getAuth();

    const updateUser = (currentUser) => {
      if (currentUser) {
        user.value = {
          displayName: currentUser.displayName,
          email: currentUser.email,
          photoURL: currentUser.photoURL,
        };
      } else {
        user.value = null;
      }
    };

    onMounted(() => {
      onAuthStateChanged(auth, (currentUser) => {
        updateUser(currentUser);
      });
    });
    watch(route, () => {
      updateUser(auth.currentUser);
    });

    const isLoggedIn = computed(() => {
      return !!user.value;
    });

    const logout = () => {
      auth.signOut().then(() => {
        user.value = null;
      });
    };

    const signInWithGoogle = () => {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          updateUser(result.user);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const isActive = (path) => {
      return route.path === path;
    };

    return {
      user,
      isLoggedIn,
      logout,
      isActive,
      signInWithGoogle
    };
  }
};

</script>

<style lang="scss">
@import "@/styles/__variables.scss";

.nav {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: transparent;
  color: #FEFEDF;
  margin: 0 2rem 5rem 2rem;

  .title {
    margin-right: auto;
    font-size: 3rem;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }

  .middle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  .nav-item {
    color: #FEFEDF;
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0 1rem;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:hover {
      color: rgba(255, 255, 255, 0.7);
      transition: color 0.3s;
    }
  }
  
  .loginlogout {
    margin-left: auto;
  }
}
</style>