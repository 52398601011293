<template>
  <nav class="top-nav">
    <TopNav />
  </nav>
  <router-view />
</template>

<script>
import TopNav from './components/TopNav.vue';

export default {
  name: 'App',
  components: {
    TopNav
  }
};
</script>

<style lang="scss">
@import "styles/reset.scss"; 
@import "styles/__variables.scss"; 

#app {
    font-family: "DM Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #FEFEDF;
    height: 100vh;
    background-color: #0E100F;
    overflow-x: hidden;
  }
</style>