import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: () => import("../components/views/UnmatchedPage.vue"),
      meta: {
        title: 'Home',
      },
    },
    {
      path: "/unmatched",
      component: () => import("../components/views/UnmatchedPage.vue"),
      meta: {
        title: 'Unmatched',
      },
    },
  ]
});

export default router;